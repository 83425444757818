$myColor: red;
$TypographyPrimaryColor: #5C626E;
$WhiteColor: #FFFFFF;
$BtnPrimaryColor: #375EC0;
$OrangePrimaryColor: #375EC0;
$LightGrey: #656565;
body{
    color : $TypographyPrimaryColor!important;
    // font-family: sofia-pro, sans-serif!important;
    // font-size: 14px!important;
}

h1 {
  color: $WhiteColor!important;
}

h5 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    
}

h6{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
}

a {
    color: $TypographyPrimaryColor!important;
}


// input{
//     height: 40px !important;
//     font-size: 14px !important;
//     line-height: 17px !important;
// }

label{
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 19px !important;
    color: #5C626E !important;
}

input::placeholder{
    color: #A6B0CF !important;
    font-family: 'Lato';
    font-size: 14px;
}

.page-content .container-fluid{
    padding: 0px 26px !important;
}

.flex-center{
    justify-content: center;
    display: flex;
    align-items: center;
}

.my-auto{
    margin-top: auto;
   margin-bottom: auto;
}


.color-font {
   color: $OrangePrimaryColor;
}

.btn-send{
    min-width: 91px!important;
    height: 32px!important;
    background: #375EC0!important;
    border-radius: 4px!important;
    font-weight: 600!important;
    font-size: 12px!important;
    line-height: 17px!important;
    color: #FFFFFF!important;
    padding-bottom: 25px!important;
}

.btn-users{
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    letter-spacing: 0.01em !important;
    color: #375EC0 !important;
}
.btn-users img{
    margin-right: 5px;
}

.btn-icon-only{
    border: none!important;
    background: transparent!important; 
    height: 50px;
    width: 50px;
    border-radius: 50%;
}
// .btn-icon-only:active{
//     background: #375EC03d!important;
//     border: 1px solid #de9175!important;
//     animation: effect_dylan 0.8s ease-out;

// }

@keyframes effect_dylan {
    50% {
      transform: scale(1.5, 1.5);
      opacity: 0;
    }
    99% {
      transform: scale(0.001, 0.001);
      opacity: 0;
    }
    100% {
      transform: scale(0.001, 0.001);
      opacity: 1;
    }
  }
  

.form-group .is-error-input{
    border: 1px solid #f46a6a!important;
}

.form-group.search-input {
    border: 1px solid #556ee6;
    border-radius: 50px;
}

.form-group .input-group {
    border: 1px solid #556ee6;
    border-radius: 50px;
}

.form-group .input-group input {
    border-radius: 50px !important;
}

.modal-body .input-group {
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
}

//settings tab
.settings-tabs-container {
    max-width: 331px;
    width: 100%;
    border: 1px solid #E6E7EF;
    border-radius: 5px;
    height: min-content;
}

.settings-tabs-container .tab {
    padding: 20px 24px;
    color: #A4A8BB;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    background: #FFFFFF;
    border-bottom: 1px solid #E6E7EF;
    cursor: not-allowed;
}

.settings-tabs-container .active {
    background: #F3F3F7;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #373B53;
    cursor: pointer;
}


//actions button
.action {
    cursor: pointer;
    border-radius: 20px;
    padding: 5px 30px 5px 30px;
    background: #27AE60;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.01em;
    display: inline-block;
    text-align: center;
}

.action:hover {
    opacity: 0.8;
}

.view {
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 30px 5px 30px;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.01em;
    display: inline-block;
    text-align: center;
}

.view:hover {
    opacity: 0.8;
    background: #ccc6c6;
}

.ql-editor {
    min-height: 150px;
}

.bell {
    margin-left: 12px;
}

.remove-button-style {
    background: none !important;
    border: none !important;
    padding: 0 !important;
}
.remove-button-style:focus {
    outline: none !important;
    box-shadow: none !important;
}

.dropdown-bell {
    display: inline-block;
}

.bell .dropdown-menu {
    width: 440px;
}

.dropdown-menu .notification-settings-header {
    width: 440px;
    height: 50px;
    background: #FFFFFF;
    border-bottom: 1px solid #ECECF5;
    color: #1A1F36;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 94px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.notification-settings-body {
    padding: 24px 18px;
    gap: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    
    color: #373B53;
}

.notification-settings-container {
    background: #FFFFFF;
    border: 1px solid #E6E7EF;
    border-radius: 5px;
}

.setting-save-button {
    background: #375EC0;
    border-radius: 50px;
    width: 164px;
    height: 50px;
    outline: 0;
    border: 0;
    color: #FFFFFF;
}

.setting-save-button:hover {
    filter: brightness(1.1);
}

.flex {
    display: flex;
}

.flex-end {
    justify-content: flex-end;
}

//border top
.bt-light {
    border-top: 1px solid #DFE6EE;
}

.text-darkBlue {
    color: #1A1F36;
}

.px-32{
    padding-left: 32px;
    padding-right: 32px;
}

.py-15{
    padding-top: 15px;
    padding-bottom: 15px;
}

.px-12 {
    padding-left: 12px;
    padding-right: 12px;
}

.p-24{
    padding: 24px;
}

.pt-26 {
    padding-top: 26px;
}

.pb-17 {
    padding-bottom: 17px;
}

.text-14 {
    font-size: 14px;
}

.text-16 {
    font-size: 16px;
}

.text-18 {
    font-size: 18px;
}

.weight-500 {
    font-weight: 500;
}

.weight-400 {
    font-weight: 400;
}

.w-full {
    width: 100%;
}

.notifaction-setting-border {
    border-top: 1px solid #ECECF5;
    border-bottom: 1px solid #ECECF5;
}

.dropdown-menu .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
}

.gap-24 {
    gap: 24px;
}

.gap-12 {
    gap: 18px;
}



//toggle switch
.custom-toggle {
    display: inline-block;
    position: relative;
    width: 29px;
    height: 20px;
  }

  .custom-toggle input {
    display: none;
  }

  .custom-toggle-primary .custom-toggle-slider {
    background-color: #ccc;
  }

  .custom-toggle-primary input:checked + .custom-toggle-slider {
    background-color: #31C48D;
  }

  .custom-toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
  }

  .custom-toggle-slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
  }

  .custom-toggle input:checked + .custom-toggle-slider:before {
    transform: translateX(9px);
  }

  .custom-toggle-slider.rounded-circle {
    border-radius: 30px !important;
  }

  .custom-toggle-slider.rounded-circle:before {
    border-radius: 30px;
  }



// status
.status{
    // opacity: 0.12;
    border-radius: 20px;
    padding: 5px 30px 5px 30px;
    background: #F0F0F0;
    color: #828282;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.01em;
    display: inline-block;
    text-align: center;
}

.status-primary {
    background: #E6F0FD !important; 
    color: #2F80ED !important;
}


.status-danger {
    background: #FDF9EA !important;
    color: #F2C94C !important;
}



.status-inprogress {
    background: #FDF9EA !important;
    color: #F2C94C !important;
}

.status-completed {
    background: #E6F8F0 !important;
    color: #2CC685 !important;
}

.status-awaiting {
    background: #E6F0FD !important;
    color: #2F80ED !important;
}

.status-proposal{
    background: #e26df5 !important;
    color: #5e0774 !important;
}

.status-archive{
    background:  #f5beac !important;
    color:#FF5722 !important;
}

.card-alert{
    background:  #F8F8FB;
    height:30px;
    font-family: 'sofia-pro';
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    color: #373B53;
}

.card-alert img {
    padding: 5px 7px;
    margin-top: -2px;
}

// end status

// .table-footer-row {
//     height: 70px;
//     border: 1px solid #E6E7EF;
//     box-sizing: border-box;
//     border-radius: 5px;
//     margin: 0px!important;
// }
// pagination
// table css

.MyTable div[data-test="datatable-info"], .MyTable div[data-test="datatable-pagination"] {
   background: #FFFFFF !important;
   padding-top: 24px;  
}
.MyTable div[data-test="table-foot"] {
    display: none !important;
 }
.MyTable thead[data-test="table-foot"] { display: none !important  }

 .MyTable div[data-test="datatable-select"] select{
    display: inline-block !important;
    width: auto !important;
 }

 .MyTable tbody[data-test="table-body"] tr{
    // cursor: pointer !important;
    
 }

 .MyTable tbody[data-test="table-body"] tr td{
    vertical-align: middle !important;
 }

//end table css

.pagination>li>a, .pagination>li>span {
    border: none!important;
    border: none!important;
}

.pagination>li>a, .pagination>li>span {
    border: none!important;
    border: none!important;
}

.page-item .page-link {
    font-weight: 500!important;
    font-size: 12px!important;
    line-height: 16px!important;
    color: #373B53!important;
}

.page-item.active .page-link {
    font-weight: 500!important;
    font-size: 12px!important;
    line-height: 16px!important;
    color: #fff!important;
    background-color: $OrangePrimaryColor!important;
    border-color: $OrangePrimaryColor!important;
}

.page-item.disabled .page-link {
    // font-size: 30px!important;
    opacity: 0.5!important;
    border-radius: 4px!important;
    content: "<"!important;
    //display: none!important;
}

.page-item .page-link  span{
    content: "↓"!important;
}



.page-item:last-child .page-link {
    // background: rgba(133, 142, 157, 0.12)!important;
    // border: 1px solid #d9e4ef!important;
    // box-sizing: border-box!important;
    // border-radius: 4px!important;
    // font-size: 30px!important;
}



// .pagination>li:first-child>a, .pagination>li:first-child>span {
//     border: none!important;
//     border: none!important;
// }

// .pagination>li:last-child>a, .pagination>li:last-child>span {
//     border: none!important;
//     border: none!important;
// }
// end pagination

//table 
table{
    // border-collapse:  collapse!important;
    border-collapse: separate!important;
    border-spacing: 0 8px!important;
}

table td:first-child  {
    border-left: 1px solid #E6E7EF;
    border-radius: 5px 0px 0px 5px;
}

table td:last-child  {
    border-right: 1px solid #E6E7EF;
    border-radius: 0px 5px 5px 0px;
}

table th  {
    border: none!important;
}


table td  {
    border-bottom: 1px solid #E6E7EF;
}




table  tbody tr{
    background: #ffff!important;
    border: 1px solid #E6E7EF!important;
    box-sizing: border-box!important;
    border-radius: 5px!important;
}

table  th{
    font-weight: 400!important;
    font-size: 12px!important;
    line-height: 17px!important;
    letter-spacing: 0.01em!important;
    color: #8A8D9E!important;

}

table  td{
    font-size: 14px!important;
    line-height: 19px!important;
    letter-spacing: 0.01em!important;
    color: #373B53!important;
    padding-top: 20px!important; 
    padding-bottom:20px!important
}

//end table 

.form-control {
    color: #495057;
    border: 1px solid #DFE6EE!important;
}

.form-control{border:none!important}
.form-control-date{padding:0!important;margin-top:-5px;}

// template overide css
.navbar-brand-box{
   background: $OrangePrimaryColor!important;
   width: 100px!important;
}

.navbar-brand-box img{
    margin-left: -10px!important;
    width: 60px!important;
    height: auto!important;
    margin-top: 30px!important;
}

.vertical-collpsed .vertical-menu {
    width: 100px !important;;
}


.btn-light{
    font-size: 16px!important;
    line-height: 21px!important;
    letter-spacing: 0.01em!important;
    color: #B0BAC5!important;
    height: 50px;
    padding: 10px 28px 10px 28px!important;
    background: transparent!important;
    border: none!important;
}



.btn-primary{
    background: $OrangePrimaryColor!important;
    border-color: $OrangePrimaryColor!important;
    border: 1px solid #E6E7EF!important;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 19px!important;
    border-radius: 25px!important;
    height: 50px;
    padding: 10px 28px 10px 28px!important;
}

.btn-primary i{
   margin-right: 10px;
}


.btn-circle {
    width: 35px!important;
    height: 35px!important;
    padding: 4px 6px!important;
    border-radius: 17px!important;
    text-align: center!important;
    line-height: 1.42857!important;
    font-size: 20px!important;
}

.metismenu a {
    z-index: 999;
}

.metismenu span{
   color: #FFFFFF!important;
   font-size: 14px;
}

.metismenu img{
    padding-left: 10px!important;
}

// .container-fluid{
//     padding-left: 40px!important;
//     padding-right: 40px!important;
// }

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 4.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.search-input input{
    background: #FFFFFF!important;
    border: 1px solid #E6E7EF!important;
    box-sizing: border-box!important;
    border-radius: 50px!important;
    height: 50px;
    padding-left: 60px!important;
    font-style: normal!important;
    font-weight: 300!important;
    font-size: 16px;
    line-height: 22px!important;
    letter-spacing: 0.01em!important;
    color: #A6ACBE!important;
    width: 336px;
}

.search-input span{
    font-size: 20px;
    color: #C9CED6!important;
    margin-top: 6px;
}

// tabs


.nav-tabs-custom {
    border-bottom: 2px solid #E6E7EF!important;
}

.nav-tabs-custom .nav-item {
    padding-bottom: 10px;
}
a.active.nav-link {
    background: none!important;
    color: $OrangePrimaryColor!important;
    font-weight: 500!important;
    font-size: 14px!important;
    line-height: 24px!important;
    letter-spacing: 0.01em!important;

}

a.nav-link {
    background: none!important;
    font-weight: 300!important;
    font-size: 14px!important;
    line-height: 24px!important;
    letter-spacing: 0.01em!important;
    color: #8A8D9E!important;
    padding: 0px!important;
}


.nav-tabs-custom .nav-item .nav-link::after {
    background: #373B53!important;
}

.navlink-second{
    margin-left: 20px;
}

// end tabs

// .page-content {
//     background: #ffff!important;
// }

// .metismenu li {
//     margin-left: 15px;
// }


// template overide css

.row-login{
   
    display: flex; 
    flex-wrap: wrap;
    height: 100vh;
}

.login-right {
   /* background-image: url("../images/login-bg.jpg");
    background-repeat: no-repeat;
    background-color: #e96b38;
    // background-size: cover;
    background-size: 100% 100%;*/
}

.bg-image img{
    background-repeat: no-repeat;
    background-color: #556ee6;
    width: 100%;
    height: 100%;
    object-fit: cover;
    text-align: right;
    position: absolute;
    right: 0px;
}

.login-left h1 {
    position: absolute;
    width: 378px;
    height: 90px;
    left: 100px;
    bottom: 100px;
    font-family: 'sofia-pro';
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 45px;
    color: $WhiteColor;
}

.login-left{
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-div{
    text-align: center;
}

.logo-div img{
    width: 120px;
}

.row-login{
    background: #FFFFFF !important;
}

// .logo-div  img{
//     width: 80px;
//     height: 48px;
// }

.form-login {
    width: 343px;
    margin-top: 60px;

    .form-group {
        .input-group {
            border: 1px solid $OrangePrimaryColor;
            border-radius: 50px;

            input {
                border-radius: 50px !important;
            }
        }
    }
}

.btn-login{
    width: 343px!important;
    height: 60px!important;
    background: $BtnPrimaryColor!important;
    border-radius: 50px!important;
    border: 1px solid $BtnPrimaryColor!important;  
    font-family: 'sofia-pro'!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 20px!important;
    line-height: 27px!important;
    margin-top: 30px;
}

.btn-login:hover{
    opacity: 0.9;
}


// checkbox css

.custom-checkbox{
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
}
.custom-checkbox{
    margin-left: 15px;
    margin-right: 15px;
}

.custom-control-label, .forgot-password{
    font-weight: 100 !important;
    font-family:"sofia-pro";
}


.custom-control-label:before{
    background-color: $OrangePrimaryColor!important;
    border: none!important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before{
    background-color:$OrangePrimaryColor !important;
    border: none!important;
}

.custom-control-label::before, 
.custom-control-label::after {
    top: -.1rem!important;
    width: 20px!important;
    height: 20px!important;
   
}

.checkbox-text{
    color: $LightGrey!important;
}

.custom-control-label{
    font-family: 'sofia-pro';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
}

.custom-checkbox{
    cursor: pointer!important;
}


// end checkbox css

.forgot-password{
    float: right;
    color: $OrangePrimaryColor!important;
    font-size: 14px!important;
    font-weight: 400!important;
    line-height: 19px;
}

.btn-login span{
    font-weight: 400;
    font-family:"sofia-pro";
}

.forgot-password:hover{
    text-decoration: underline!important;
    color: $OrangePrimaryColor!important;
}

.back-home-container{
    opacity: 0.5;
    position: absolute;
    left: 50px;
    top: 0;

    .back-home{
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        margin-left: 12px;
        font-family:"sofia-pro";
    }

    i{
        font-size: 18px;
    }
}

.login-text {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 49px;
    color: #323842;
    margin-top: 60px;
    margin-bottom: 60px;
    text-align: center;
    font-family: 'sofia-pro';
}

.password-field {
    position: absolute;
    text-align: right;
    right: 10px;
    top: 10;
    margin-top: 10px;
    cursor: pointer;
    z-index: 99;
}

.password-icon {
    border-left:0 solid transparent;
    background:transparent;
}

.footer-login{
  font-size: 16px;
  color: #707680;
  margin-top: 50px;
  line-height: 32px;
  font-family: 'sofia-pro';
  opacity: 0.7;
}

.icon-footer{
    width: 19.45px;
    height: 13.38px;
    margin-right: 5px;
}

.icon-footer-call{
    height: 17px;
    margin-right: 5px;
}


.navbar-header {
    border-bottom: 1px solid #E6E7EF;
}
// header 

.profile-name{
    width: 200px;
}


.Aligner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Aligner-item {
    max-width: 100%;
    text-align: end
}

.Aligner-item--top {
align-self: flex-start;
}

.Aligner-item--bottom {
align-self: flex-end;
}

.credit-balance{
    width: 345px;
    height: 61px;
    background: #F8F8FB;
    border: 1px solid #E6E7EF;
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 16px;
    color: #373B53;
    margin-top: 25px;
}

.show-balance{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    color: #373B53;
    margin-top: 25px;
}

.cbalance {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 0;
}

.tbalance {
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 0;
}
// end header

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
 }
 

.page-title-box{
    font-weight: 500!important;
    font-size: 34px!important;
    line-height: 33px!important;
    letter-spacing: 0.01em!important;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.page-title-box h2{
    margin-right: 10px;
    font-size: 22px;
    margin-bottom: 0px;
}

.footer-table{
    padding-top: 10px;
}

.per-page{
    font-weight: 300!important;
    font-size: 14px!important;
    line-height: 19px!important;
    letter-spacing: 0.01em!important;
    color: #373B53!important;
    padding-top: 25px;
}


.table-footer-row {
    height: 70px;
    border: 1px solid #E6E7EF;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 0px!important;
}

.header-profile-user {
    height: 50px!important;
    width: 50px!important;
}

.profile-arrow{
    font-size: 20px!important;
    color: #C9CED6!important;
}

.top-line-spacer{
    margin-top: 18px;
    margin-bottom: 18px;
    border-left: 1px solid #EFF4F7;
}



a.waves-effect.active  img{
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 13px 12px;
    
}

.calendar-icon{
    position: absolute;
    right: 0px;
    margin-top: -25px;
    margin-right: 30px;
}

.react-datepicker__day.react-datepicker__day--013.react-datepicker__day--selected.react-datepicker__day--today {
    background: $OrangePrimaryColor!important;
}

.dropzone h4{
    color: #798A95;
}

.dropzone p{
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    color: #B0BAC5;
}

.dropzone h4 span{
    color: $OrangePrimaryColor;
}

.dropzone {
    min-height: 288px !important;
}

.numberCircle {
    display: inline-block;
    border-radius: 50%;
    font-size: 18px;
    background: #F7E5E1;
    color: #375EC0;
}

.numberCircle:before,
.numberCircle:after {
    content:'\200B';
    display:inline-block;
    line-height:0px;

    padding-top:50%;
    padding-bottom:50%;
}

.numberCircle:before {
    padding-left:8px;
}
.numberCircle:after {
    padding-right:8px;
}

// spinner
#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:100px;height:100px;
    border-style:solid;
    border-color:$OrangePrimaryColor;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}
//end spinner

// credit card
.card-div{
    width: 403px;
    height: 239px;
    background: #FFFFFF;
    border: 1px solid #E6E7EF;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 20px;
}

.card-div .flex-item img{
    max-height: 75px;
 }


.card-flex-container {
    padding: 0;
    margin: 0;
    list-style: none;
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
  }
  

  
 
  .card-flex-container .flex-item {
    flex: 2 1 auto;
  }

  .card-center-content{
    // text-align: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    color: #373B53;
    padding: 30px 0px 30px 0px;
  }

  .card-flex-container .card-name {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #373B53;

  }

  .card-flex-container .valid {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #373B53;
    padding-right: 30px!important;
}

.new-card-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 403px;
    height: 239px;
    background: rgba(238, 238, 243, 0.7);
    border: 2px dashed #DDDFEA;
    box-sizing: border-box;
    border-radius: 20px;
    cursor: pointer;
}

.new-card-div span{
    padding-top: 30px;
    font-size: 18px;
    line-height: 24px;
    color: #A1A8BB;
}

.new-card-div:active{
    content: "";
    background: #375EC0;
    border: 2px dashed #375EC0;
    transition: all 3s ease-out;
    
}

.row-card{
    // padding: 30px;
}

.row-card .w-400{
    padding-left: 15px;
    padding-top: 15px;
}


.credit-card-imgs img{
    height: 40px;
    margin-top: -10px;
    
}

.credit-card-imgs img:not(:first-child) {
   margin-left: 5px;
}

// end credit card


// notification

.display-none{
    display: none;
}
/* The snackbar - position it at the bottom and in the middle of the screen */
.notification-div {
    visibility: hidden; /* Hidden by default. Visible on click */
    top: 30px;
    right: 30px;
    cursor: pointer;
    width: 321px;
    height: 113px;
    background: rgba(255, 255, 255, 0.95);
    border: 1px solid #E6E7EF;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.12);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    position: fixed;
    top: 30px;
    right: 30px;
    z-index: 1050;
  }
  
  /* Show the snackbar when clicking on a button (class added with JavaScript) */
  .notification-div.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 5s;
    animation: fadein 0.5s, fadeout 0.5s 5s;
    z-index: 9999;
  }
  
.notification-div img{
    width: 86px;
}
.notification-div .notification-details h4{
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #373B53;
}

.notification-div .notification-details p{
    font-weight: 300;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #373B53;
}


.ticket-details {
    position: fixed;
    z-index: 4;
    right: 0px;
    top: 99px;
    min-width: 613px;
    max-width: 613px;
}

.remove-attachment{
    text-align: right;
    margin-right: 10px;
    
    .fa-times{ cursor: pointer }
    .fa-times:hover{ color: #375EC0 }
}

.ticket-details .card {
    // margin: 20px;
}

.card{
    background: #FFFFFF!important;
    border: 1px solid #E6E7EF!important;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08)!important;
}

.card-header{
    height: 53px!important;
    display: flex!important;
    align-items: center!important;
    letter-spacing: 0.01em!important;
    background: none!important;
    border-bottom: 1px solid #E6E7EF!important;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #8A8D9E;
}


.ticket-details .card-body {
    height: 100vh;   
    overflow: auto;
    
}

.ticket-details .p-tag {
    line-height: 19px;  
    letter-spacing: 0.01em;
   color: #8A8D9E;
}

.ticket-details .title-1 {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.01em;
    color: #8A8D9E;
}

.ticket-details .title-2 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.01em;
    color: #373B53;
}

.ticket-details .download-link {
    color: #375EC0 !important;
}


.ticket-details .card-details {
    height: calc(100vh - 190px);
    overflow-y:auto;
    overflow-x: hidden;
    scroll-margin: 100px 0 0 100px;
    padding-right: 30px !important;
    // overflow: hidden;
   
}

// .ticket-details .card-details:hover {
//     overflow-y: scroll;
//   }



.ticket-details .card-details::-webkit-scrollbar {
    width: 5px;
}

.ticket-details .card-details::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
.ticket-details .card-details::-webkit-scrollbar-corner {
    background-color: transparent;
}

.ticket-details .card-details::-webkit-scrollbar-thumb{
    background-color: #E6E7EF;
    // outline: 1px solid slategrey;
    border-radius: 10px;
    // scroll-padding-right: 20px;
}



.circle-badge{
    height: 15px;
    width: 15px;
    border: 4px solid #bbb;
    box-sizing: border-box;
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
}




.circle-badge-primary {
    border: 4px solid #2F80ED !important; 
}


.circle-badge-danger {
    border: 4px solid #F2C94C !important;
}

.circle-badge-inprogress {
    border: 4px solid #F2C94C !important;
}

.circle-badge-completed {
    border: 4px solid #2CC685 !important;
}

.circle-badge-awaiting {
    border: 4px solid #2F80ED !important;
}

.circle-badge-proposal{
    border: 4px solid #5e0774 !important;
}

.circle-badge-archive{
    border: 4px solid  #FF5722 !important;
}

.circle-badge-warning{
    border: 4px solid #F2C94C;
}


//flex styles container

.display-flex-ce {
    display: flex;
}

//approve modal styles
.approve .modal-content {
    padding: 2px 8px;
    font-family: 'sofia-pro' !important;
    font-style: normal;
    font-weight: 400;
}

.approve .modal-header {
    border-bottom: none;
    background: #F8F8FB;
    margin: 0 -8px;
    padding: 1rem 1.5rem;
}

.approve h5{
    font-size: 1.125rem !important;
}

.approve .modal-body {
    padding: 1.5rem 1rem;
    font-weight: 300;
    font-size: 0.875rem;
    color: #373B53;
}

.approve .modal-footer {
    justify-content: space-between;
    padding: 0 0.75rem 1.4375rem;
    border-top: none;
}

.approve .approve-button {
    height: 50px;
    line-height: 50px;
    text-align: center;
    width: 185px;
    background: #F6F7F8;
    border-radius: 50px;
    cursor: pointer;
    font-size: 1rem;
    letter-spacing: 0.01em;
}

.approve .cancel {
    background: #F6F7F8;
    color: #B0BAC5;
}

.approve .approve {
    background: #32B268;
    color: #FFFFFF;
}




//text styles container

.text-black {
    color: black!important;
}

.text-grey {
    color: grey!important;
}

.text-small {
    font-size: 12px;
}

.text-h-16 {
    line-height: 16px;
}

  
  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

// end notification


.table-attachment {
    margin-left: -10px;
    margin-top: 20px;
}

.attachment-list-row .col-md-6{
    margin-top: 19px;
}



.table-attachment tr, .table-attachment td{
  border: none!important
}
.table-attachment .border-center{
    background: #ECECF5;
    width: 100%;
    min-height: 1px;
}

.attachment-list-row{
    // margin: 0px  70px 0px 0px !important;
    margin-right: 33px !important;
    padding-left: 0px !important;
}

.attachment-list-row small{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #A6B0CF;
}

.attachment-list{
    background: #F8F8FB;
    border: 1px solid #ECECF5;
    box-sizing: border-box;
    border-radius: 4px;
}

.attachment-list th, .attachment-list td{
    border: none !important;
}


.communication-theard{
    display: flex;
    flex-direction: row;
    margin-top: 40px;
}



.communication-image{
    padding-right: 20px;
}

.communication-details{
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #ECECF5;
    box-sizing: border-box;
    border-radius: 4px;
}


.communication-details textarea{
    padding-left: 20px;
    padding-right: 20px;
    border: 0px !important;
    resize: none;
}

.communication-details textarea::placeholder{
   color: #A6B0CF;
}


.communication-content{
    border-bottom: 1px solid #ECECF5;
    padding-bottom: 15px;
    width: 100%;
}


.communication-content .date-message{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #A6B0CF;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.communication-content h4{
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #495057;
}


.communication-content h5{
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #A6B0CF;
}


.close-button{
    position: absolute;
    right: 30px;

}

.action-footer{
    margin: 10px 20px 10px 20px;
    border-top: 1px solid #ECECF5;
    padding-top: 12px;
}

.action-footer2{
    margin: 5px 20px 10px 20px; 
    padding-top: 12px;
}

.action-footer .attach-file {
    padding-top: 7px;
    color: #A6B0CF;
}

// skeleton 
.skeletonCOPY:empty {
    margin: auto;
		width: 500px;
		height: 600px; /* change height to see repeat-y behavior */

		background-image:
			radial-gradient( circle 50px at 50px 50px, lightgray 99%, transparent 0 ),
			linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
			linear-gradient( lightgray 20px, transparent 0 ),
			linear-gradient( lightgray 20px, transparent 0 ),
			linear-gradient( lightgray 20px, transparent 0 ),
			linear-gradient( lightgray 20px, transparent 0 );

		background-repeat: repeat-y;

		background-size:
			100px 200px, /* circle */
			50px 200px, /* highlight */
			150px 200px,
			350px 200px,
			300px 200px,
			250px 200px;

		background-position:
			0 0, /* circle */
			0 0, /* highlight */
			120px 0,
			120px 40px,
			120px 80px,
			120px 120px;

		animation: shine 1s infinite;
	}

	@keyframes shine {
		to {
			background-position:
				0 0,
				100% 0, /* move highlight to right */
				120px 0,
				120px 40px,
				120px 80px,
				120px 120px;
		}
    }
    


//end skeleton

.modal-general .modal-body{
    text-align: center;
    padding-bottom: 40px;
}

.modal-general .modal-header {
    border-bottom: 1px solid transparent !important;
}

.modal-general p{
    line-height: 5px;
}

.or-div {
    width: 100%;
    text-align: center;;
}

.or-div table{
    width: 100%;
}

.or-div  tr, .or-div  td{
    border: none !important;
}


.or-div .td-1 div{
    border: 1px solid #ECECF5 !important;
}

.or-div .td-2{
    font-size: 14px;
   line-height: 17px;
   color: #5C626E;
   width: 30px;
}

.or-div .td-3 div{
    border: 1px solid #ECECF5 !important;
}

.ql-editor{
    height: 200px;
}

.ql-container.ql-snow {
    border: 1px solid #ECECF5 !important;
}

.ql-toolbar.ql-snow {
    border: 1px solid #ECECF5 !important;
}

.row-attachment{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px;

}
.row-attachment h5{
    font-size: 12px !important;
}
.row-attachment .column{
    display: flex;
    flex-direction: column;
}

.row-attachment .column-1{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.row-attachment .column-2{
    display: flex;
    flex-direction: column;
    flex: 2;
    padding-left: 10px;
    justify-content: center;
    
}

.row-attachment .column-3{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}


.circle{
    position: relative;
    width: 40px;
    height: 40px;
    background: rgba(84, 108, 229, 0.12);
    border-radius: 50%;
  }
  .circle span{
    position:absolute;
    top:50%; left:50%;
    transform: translate(-50%, -50%);
    margin:0;
    color: #375EC0;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    font-weight: 500;
  }



 // recharge css
 .recharge-balance h3{
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #323842;
 }

.small-add-card{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 145px;
    height: 84px;
    background: #F8FAFC;
    border: 1.5px dashed #DDDFEA;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px 10px;
    margin-top: 20px;
}

.small-add-card:active{
    content: "";
    background: #375EC0;
    border: 2px dashed #375EC0;
    transition: all 3s ease-out;
    
}

.small-add-card img{
    width: 20px;
    height: 20px;   
}

.small-add-card span{
    font-size: 12px;
    letter-spacing: 0.01em;
    color: #A1A8BB;
}


.small-card-list{
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    border: 1px solid #E2E8EF;
    border-radius: 10px;
    flex-direction: row;
    width: 297px;
    margin-top: 20px;
    height: 84px;
    margin-right: 20px;
    cursor: pointer;
}



.small-card-list .row-1{
    padding: 3px;
    background: rgba(37, 102, 175, 0.12);
    border-radius: 10px 0px 0px 10px;
    display: flex;
    align-items: center;
}



// ripple
.ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  
  .ripple:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #00000047 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }
  
  .ripple:active:after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
  }


//ripple dropdown
.ripple-dropdown {
    position: relative;
    transform: translate3d(0, 0, 0);
  }
  
  .ripple-dropdown:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #00000047 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }
  
  .ripple-dropdown:active:after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
  }



.small-card-list .row-1 img{
    width: 58px;
    height: 42px;
    margin: 0px 10px;
}
.small-card-list .row-2{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: auto;
}

.small-card-list .row-2 .card-number{
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #373B53;
}

.small-card-list .row-2 .date{
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    color: #A6ACBE;
}
.small-card-list .row-3{
    padding: 10px;
}

.small-card-list:hover  .checkbox{
    background: url('../images/checkIcon.png') #375EC0 no-repeat !important;
    background-position: center center !important;
    border: 1px solid #375EC0 !important;
}

.checkbox{
    background: #F2F5F9;
    border: 1px solid #EAEDF0;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    cursor: pointer;
}
.checkbox:hover{
    background: url('../images/checkIcon.png') #375EC0 no-repeat;
    background-position: center center;
    border: 1px solid #375EC0;
}
.active-checkbox .checkbox{
    background: url('../images/checkIcon.png') #375EC0 no-repeat !important;
    background-position: center center !important;
    border: 1px solid #375EC0 !important;
}

.tab-plan {
    display: flex;
}

.tab-plan div{
    font-size: 16px;
   line-height: 22px;
   color: #7C8592;
   margin-right: 20px;
   border-radius: 50px;
   min-height: 42px;
   min-width: 160px;
   align-items: center;
   display: flex;
   justify-content: center;
   cursor: pointer;
   transition: background 0.8s;
}



.tab-plan div.active-tab{
    background: rgba(241, 90, 35, 0.12);
    color: #375EC0;
    cursor: default;
}

.tab-plan div:hover{
    background: rgba(241, 90, 35, 0.12);
    color: #375EC0;
}


.price-option{
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #E2E8EF;
    border-radius: 10px;
    flex-direction: column;
    // width: 142px;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
}




.price-option:hover {
    border: 1px solid #375EC0;
    box-shadow: 0px 4px 20px rgba(241, 90, 35, 0.1);
   
}

.price-option:hover  .column-2 {
    background: rgba(241, 90, 35, 0.08);
}

.price-option:hover  .checkbox {
    background: url('../images/checkIcon.png') #375EC0 no-repeat !important;
    background-position: center center !important;
    border: 1px solid #375EC0 !important;;
}

.price-option .column-1{
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #323842;
    padding: 10px 17px;
}


.price-option .currency-div{
    display: flex;
    flex-direction: column;
    color: #7C8592;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    padding: 20px 17px;
}

.price-option .currency-div .price{
   color: #323842;
   margin-left: 3px;
}

.price-option .sale-div{
    font-weight: 300;
    font-size: 9px;
    line-height: 12px;
    text-decoration-line: line-through;
    color: #323842;
    opacity: 0.6;
}


.active-option {
    border: 1px solid #375EC0;
    box-shadow: 0px 4px 20px rgba(241, 90, 35, 0.1);
   
}

.active-option  .checkbox {
    background: url('../images/checkIcon.png') #375EC0 no-repeat !important;
    background-position: center center !important;
    border: 1px solid #375EC0 !important;;
}

.active-option  .column-2 {
    background: rgba(241, 90, 35, 0.08);
}

.plan-title{
    font-weight: 500;
   font-size: 16px;
   line-height: 22px;
   color: #323842;
   margin-top: 20px;
//    margin-bottom: 10px;
}

.plan-title img{
    margin-right: 10px;
    cursor: pointer;
}



.recharge-footer .text-information{
    display: flex;
    align-self: baseline;
    color: #8D99A6;
}

.recharge-footer .text-information img{
    display: flex;
    align-self: baseline;
    padding-top: 3px;
    margin-right: 5px;
}

.plan-content {
    padding-left: 10px;
    padding-right: 10px;
}

.loading-div{
    padding: 10px 0px;
    font-size: 14px;
}

 // end recharge css

 // user management
 .user-div{
    background: #FFFFFF;
    border: 1px solid #E6E7EF;
    box-sizing: border-box;
    border-radius: 10px;    
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    
 }

 .user-div .row-2{
    display: flex;
    align-items: center;
    padding: 0px 10px;
    min-width: 200px;
    max-width: 200px;
 }

 .user-div .row-2 .name{
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #373B53;
 }

 .user-div .row-2 .email{
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #A6ACBE;
 }

.user-div .row-2 .name,
.user-div .row-2 .email {
    min-width: 180px;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

 .user-div .row-2 ul{
    list-style-type: none;
    padding: 0px;
 }

 .ripple.menu-button {
    padding: 10px 18px;
    border-radius: 50%;
}

.ripple-dropdown.menu-button img {
    padding: 10px 18px;
    border-radius: 50%;
}

// end user management
.dropdown-menu{
    min-width: 5rem!important;
    border-radius: 10px!important;
}

.dropdown-edit{
    span{color: #373B53}
    i{ 
        color: #A6ACBE;
        margin-right: 5px;
    }
}

.dropdown-delete{
    span{color: #373B53}
    i{ 
        color: #A6ACBE;
        margin-right: 8px;
    }
}
//dropdown


 //end dropdown



 .logout-button {
    display: flex;
    align-items: center;
 }

 .right-action-container{
    display: flex;
    flex-flow: wrap row;
    justify-content: flex-end;
 }

 .right-action-container .row-1{
   margin-right: 20px;
 }


 .right-action-container .row-2{
    // display: flex;
    // flex: 1;
 }

// complete account
.complete-account-links {
    color: #707680 !important;
    height: 25px;
    font-family: 'sofia-pro';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.complete-account-home-link {
    position: absolute;
    left: 42px;
    top: 32px;
    height: 27px;
    opacity: 0.7;
    font-family: 'sofia-pro';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #919EAC;
}

.complete-account-user-email {
    width: 100%;
    // height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(193, 193, 217, 0.4);
    border-radius: 50px;
    text-align: center;
    padding-top: 9px;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #323842;
    margin-top: -19px;
    margin-bottom: 44px;
    margin-left: auto;
    margin-right: auto;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

// .new-card-div:active{
//     content: "";
//     background: #375EC0;
//     border: 2px dashed #375EC0;
//     transition: all 3s ease-out;
    
// }
/*\
 * Restore Bootstrap 3 "hidden" utility classes.
\*/

.summary {
    background: #F8F8FB; 
    border-radius: 7px 0px 0px 7px;
    margin-top: -15px;
   
}

.summary-body {
    padding: 30px;
    
}


.summary-body-table {
    border-top: 1px solid #E2E8EF;
    margin: 20px 0px;
    padding-bottom: 10px;
}


.summary-body-table .border-tr{
    border-top: 1px solid #E2E8EF;
    min-height: 20px;
}


.summary-body h2{
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
}

.summary-body table{
    width: 100%;
    font-weight: 500;
   font-size: 14px;
}

.summary-body table td{
    border: none !important;
    background: #F8F8FB !important; 
    padding: 6px 0px !important;
    font-size: 14px;
    line-height: 150% !important;
    color: #5C626E !important;
}

.summary-body table td:nth-child(2){
    text-align: right;
}

.code-div{
    display: flex;
    justify-content: space-around;
}

.input-item{
    flex: 1;
}

.input-item input{
    width: 100%;
    background: #FFFFFF;
    border-radius: 0px!important;
    border: none !important;
    height: 48px;
    padding-left: 5px;
    padding-right: 5px;
   
}



.button-item button{
    
    border-radius: 0px 4px 4px 0px !important;
    background: #375EC0 !important;
    border-color: #375EC0 !important;
    border: 1px solid #E6E7EF !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    height: 52px;
    padding: 10px 28px 10px 28px !important;
    color: #fff !important;
    margin-top: -2px;
}

.button-item.active button{
    background: #009688 !important;
}


.total-savings{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #E3F4ED !important;
    border-radius: 4px !important;
    color: #46D582 !important;
    height: 40px;
    flex-direction: row;
    padding: 0px 20px;
}

.total-amount{
    font-size: 17px !important; 
}


// anootation

.container-flex {
    display: flex;
    width: calc(100vw - 55px);
  }
  
  .column.left {
    width: calc(100vw - 520px);
  }
  
  .column.right {
    // width: 100px;
    // flex: 0  100px;
    flex-basis:  520px;
     flex-shrink: 0;
  }
  
  .column.center {
    flex: 1;
    text-align: center;
  }
  
.container-flex .row-1{
    padding: 0px  20px;
    //flex: 1;
}

.container-flex .row-1 .image-annotation img{
    max-width: 100%;
    // padding-top: 15px;
}

.container-flex .row-2{
    justify-content: space-around;
    background: #FFFFFF;
    margin-top: -25px;
   
}

.container-flex .row-2 .header-text{
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #373B53;
    padding: 20px 0px;
    padding-left: 20px;
}

.container-flex .row-2 .header-subtext{
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    border-top: 1px solid #E6E7EF;
    border-bottom: 1px solid #E6E7EF;
    padding: 20px 0px;
    padding-left: 20px;
}

.annotation-action-div{
    //max-height: calc(100vh - 160px);
    position: fixed;
    background: #fff;
    border-left: 1px solid #E6E7EF;
    max-height: 100vh;
    overflow-y: auto;
    border-right: 5px solid transparent;
    top: 100px;
    z-index: 1000;
    padding-bottom: 100px;
}

.annotation-action-div::-webkit-scrollbar {
    width: 5px;
}

.annotation-action-div::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}

.annotation-action-div::-webkit-scrollbar-corner {
    background-color: transparent;
}

.annotation-action-div::-webkit-scrollbar-thumb{
    background-color: #E6E7EF;
    // outline: 1px solid slategrey;
    border-radius: 10px;
    // scroll-padding-right: 20px;
}

.marker-div{
    display: flex;
    flex-wrap: wrap;
    padding: 7px 20px 20px 25px;
}


 .marker-div .toolbar-button{
    width: 68px;
    height: 68px;
    background: #FCFCFC;
    border: 1px solid #F2F3F7;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 10px;
    margin-top: 10px;
}

.marker-div .toolbar-button .menu-inside{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    font-size: 12px;
    line-height: 13px;
    text-align: center;
    color: #5C626E;
    cursor: pointer;
}



.marker-div .toolbar-button .menu-inside .text{
    margin-top: 10px;
}

.marker-div-container{
    // max-height: 40vh;
    // overflow-y: auto;
    // background: red;
}

.toolbar-group{
   background: #FFFFFF;
   border-left: 1px solid #E6E7EF;
   margin-top: -24px;
}

.markerjs-logo{
    display: none !important;
}

.annotation-action{
    
}

.annotation-start{
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    width: 300px;
    padding: 10px;
    cursor: pointer;
    height: 40px;
}

.annotation-after{
    background: #FFFFFF;
    border-radius: 10px 10px 0px 0px;
    width: 120px;
    padding: 10px;
}

.annotation-after{
    display: flex;
    justify-content: space-around;
    height: 40px;
}


.annotation-after .check-action{
    color: #49CE6E;
    font-size: 15px;
    //padding: 10px;
    cursor: pointer;
}

.annotation-after .close-action{
    color: #373B53;
    font-size: 15px;
    //padding: 10px;
    cursor: pointer;
}

.annotation-after .border-center{
    border-left: 1px solid #E6E7EF;
    height: 20px;
}

.ticket-info{
    padding: 20px;
}

// end annotation


#page-topbar {
    z-index: 1001 !important;
}


.avatar-div{
    display: flex;
    align-items: center;
    margin: 0px 13px;
}

.col-message-data{
    display: flex;
    width: 100%;
    padding: 10px;
    flex-direction: column !important;
}

.header-title{
    font-weight: 300;
}






















/* Breakpoint XS */
@media (max-width: 575px)
{
    

    .form-login{
        width: 85%;
    }

    .btn-login{
        width: 275px;
    }

    .forgot-password{
        font-size: 14px;
    }

    .right-action-container{
        justify-content: flex-start;
        padding-bottom: 20px;
    }

    

}

/* Breakpoint SM */
@media (min-width: 576px) and (max-width: 767px)
{
    
}

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px)
{
    
}

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px)
{
    
}

/* Breakpoint XL */
@media (min-width: 1200px)
{
   
}

//SWAL
.swal2-close{
    top: 10px;
    right: 10px;
}

.swal-custom-cancel{
    color: #B0BAC5!important;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 14px!important;
    line-height: 21px;
    border-radius: 25px!important;
    padding: 10px 40px;
}

.swal-custom-confirm{
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 14px!important;
    line-height: 21px;
    border-radius: 25px!important;
    padding: 10px 40px;
}

.swal-custom-content{
    display: block;
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 14px!important;
    line-height: 23.8px;
    padding: 15px 15px;
}

.swal-custom-title{
    font-family: 'Poppins';
    font-weight: 500;
    font-style: 18px!important;
    line-height: 27px;
    color: #3F4667!important;
    margin-top: 18px;
}

.swal-custom-popup{
    width: 30em;
    padding: 3.25em 1.25em;
}

.padding-0{padding:0!important}
.padding-0 .react-datepicker{left:-68px;}//inbox > add request (due date)
.datepicker-container{padding-left:0px!important}

.__markerjs2_ {
    left: 132px !important;
    top: 127px !important;
}

// login as contact loader - start
:root
{
	--duration: 1.5s;
	--container-size: 250px;
	--box-size: 33px;
	--box-border-radius: 15%;
}
.loader-container{
    width: 100vw !important;
}
.loader-text{
    font-size: 24px;
    color: #556ee6;
    margin-right: 20px;
}
.loader-boxes
{
	width: var(--container-size);
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}
.📦
{
	width: var(--box-size);
	height: var(--box-size);
	position: relative;
	display: block;
	transform-origin: -50% center;
	border-radius: var(--box-border-radius);

	&:after
	{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background-color: #556ee6;
		border-radius: var(--box-border-radius);
		box-shadow: 0px 0px 10px 0px rgba(#556ee6, 0.4);
	}
	
	&:nth-child(1)
	{
		animation: slide var(--duration) ease-in-out infinite alternate;
		&:after{ animation: color-change var(--duration) ease-in-out infinite alternate; }
	}
	
	@for $i from 1 to 5 
	{
		&:nth-child(#{$i + 1})
		{	
			animation: flip-#{$i} var(--duration) ease-in-out infinite alternate;
			&:after{ animation: squidge-#{$i} var(--duration) ease-in-out infinite alternate; }
		}
	}
	
	&:nth-child(2):after{ background-color: #EC6600; }
	&:nth-child(3):after{ background-color: #F37B00; }	
	&:nth-child(4):after{ background-color: #F99000; }
	&:nth-child(5):after{ background-color: #FFA500; }
}
@keyframes slide
{
	0% { background-color: #556ee6; transform: translatex(0vw); }
	100% { background-color: #FFA500; transform: translatex(calc(var(--container-size) - (var(--box-size) * 1.25))); }
}
@keyframes color-change
{
	0% { background-color: #556ee6; }
	100% { background-color: #FFA500; }
}
@for $i from 1 to 5 
{	
    @keyframes flip-#{$i} {
      0%, #{$i * 15}% { transform: rotate(0); }  
      #{$i * 15 + 20}%, 100% { transform: rotate(-180deg); }
    }
	
	@keyframes squidge-#{$i}
	{
		#{$i * 15 - 10}% { transform-origin: center bottom; transform: scalex(1) scaley(1);}
		#{$i * 15}% { transform-origin: center bottom; transform: scalex(1.3) scaley(0.7);}
		#{$i * 15 + 10}%, #{$i * 15 + 5}% { transform-origin: center bottom; transform: scalex(0.8) scaley(1.4);}
		#{$i * 15 + 40}%, 100% { transform-origin: center top; transform: scalex(1) scaley(1);}
		#{$i * 15 + 25}% { transform-origin: center top; transform: scalex(1.3) scaley(0.7);}
	}
}
// login as contact loader - end

.__markerjs2_ .__markerjs2__0_toolbar {
    display: none !important;
}