.sideNavItem {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 26px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.icon {
  height: 54px;
  width: 54px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: transparent;
}

.iconActive {
  background-color: white;
}

.sideNavItem span {
  padding: 0px !important;
}